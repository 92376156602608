(function($) {

	$(function() {
		var blockContainer = $(".content-blocks");
		if (blockContainer.length === 0) {
			return;
		}

		var blocks = blockContainer.find(".block-link");
		blocks.on("click",
			function() {
				var url = $(this).data("href");
				if (url.length > 0) {
					window.location.pathname = url;
				}
			});
	});
})(jQuery);