(function ($) {
    "use strict";

    var infiniteScroller = function () {
        var hasMore = true;
        var page = 2;
        var language = window.location.pathname.replace("/", "").split('/')[0];
        
        if (typeof language === "undefined") {
            return;
        }

        var url = "/projects/getprojects/" + language + "/" + page;

        $(window)
            .scroll(function () {
                if ($(window).scrollTop() === $(document).height() - $(window).height() && hasMore) {
                    $.get(url,
                        function (data) {
                            hasMore = data.HasMore;
                            page++;

                            $('.container-projects .row').append(data.HtmlString);
                        });
                }
            });
    };

    $(function () {
        new infiniteScroller();
    });
})(jQuery);