(function ($) {
	var validationIcons = function () {
		var inputs = $("form input");
		var textarea = $("form textarea");

		validateForm(inputs);
		validateForm(textarea);
	};

	var validateForm = function (inputs) {
		inputs.each(function (index, input) {
			var checkmark = $(input).parent().find(".icon-checkmark");
			var error = $(input).parent().find(".icon-error");

			if (checkmark.length < 1 || error.length < 1) {
				return;
			}

			$(input).on("change paste keyup keypress focusout", function () {
				if ($(input).hasClass("valid")) {
					checkmark.show();
					error.hide();
				} else if ($(input).hasClass("input-validation-error")) {
					checkmark.hide();
					error.show();
				}

				setTimeout(function () {
					if ($(input).hasClass("valid")) {
						checkmark.show();
						error.hide();
					} else if ($(input).hasClass("input-validation-error")) {
						checkmark.hide();
						error.show();
					}
				}, 1000);
			});

			$(".order-form .button").on("click", function (e) {
				var dropdown = $(".table select");
				var formNotValid = true;

				dropdown.each(function () {
					if ($(this).val() > 0) {
						formNotValid = false;
					}
				});

				if (formNotValid === false) {
					dropdown.each(function () {
						$(this).removeClass("select-error");
					});
				} else {
					dropdown.each(function () {
						$(this).addClass("select-error");
					});
				}

				/*setTimeout(function () {
					if ($(input).hasClass("valid")) {
						checkmark.show();
						error.hide();
					} else if ($(input).hasClass("input-validation-error")) {
						checkmark.hide();
						error.show();
					}
				}, 250);
*/
				if (formNotValid && $("#OrderForm_LastName").val() !== "") {
					e.preventDefault();
					e.stopPropagation();
				}
			});
		});

		$("form .button").on("click", function () {
			inputs.each(function (index, input) {
				var checkmark = $(input).parent().find(".icon-checkmark");

				if (checkmark.length < 1) {
					return;
				}

				var error = $(input).parent().find(".icon-error");
				setTimeout(function () {
					if ($(input).hasClass("valid")) {
						checkmark.show();
						error.hide();
					} else if ($(input).hasClass("input-validation-error")) {
						checkmark.hide();
						error.show();
					}
				}, 250);
			});
		});
	};

	var initOrderCalculation = function () {
		var self = this;

		self.BTWPercentage = parseFloat($(".btw").html());
		if (self.BTWPercentage > 1) {
			self.BTWPercentage = parseFloat(self.BTWPercentage / 100);
		}
		self.shippingCosts = parseFloat($(".shipping-costs").html());
		self.freeShippingCostsMinimum = parseFloat($(".free-shipping-costs-minimum").html());
		self.freeShipping = false;

		self.products = $(".product");

		var dropdown = $(".table select");

		dropdown.change(function () {
			var amount = $(this).val();
			var product = $(this).closest(".product");
			var price = replaceComma(product.find(".price").html());

			var totalPrice = parseFloat(parseFloat(amount) * parseFloat(price));
			product.find(".total-price").html(replacePoint(totalPrice.toFixed(2)).concat(" €"));

			self.updateTotals();

			$(this).removeClass("select-error");
		});
	};

	initOrderCalculation.prototype.updateTotals = function () {
		var self = this;
		var totalPrice = 0;
		self.freeShipping = false;

		this.products.each(function () {

			var price = $(this).find(".total-price").html();

			if (typeof price !== "undefined" && price !== "") {
				totalPrice += parsePrice($(this).find(".total-price").html());
			}

			if (parseFloat($(this).find("select").val()) >= self.freeShippingCostsMinimum) {
				self.freeShipping = true;
			}
		});

		var totalBtw = totalPrice * parseFloat(this.BTWPercentage);
		var subTotal = totalPrice - parseFloat(totalBtw);
		//console.log(this.freeShipping);
		if (this.freeShipping) {
			$(".shipping-costs-free").show();
			$(".costs-shipping-costs").hide();
			$(".costs-shipping-costs").next(".euro-sign").hide();
		} else {
			$(".shipping-costs-free").hide();
			$(".costs-shipping-costs").show();
			$(".costs-shipping-costs").next(".euro-sign").show();

			totalPrice += this.shippingCosts;
		}

		this.setPrice(".costs-subtotal", subTotal);
		this.setPrice(".costs-shipping-costs", this.shippingCosts);
		this.setPrice(".costs-total-btw", totalBtw);
		this.setPrice(".costs-total-price", totalPrice);
	};

	initOrderCalculation.prototype.setPrice = function (selector, price) {
		$(selector).html(replacePoint(price.toFixed(2)));
	}

	function parsePrice(price) {
		return parseFloat(replaceComma(price.replace("€", "")));
	}

	function replaceComma(str) {
		return str.replace(",", ".");
	}

	function replacePoint(str) {
		return str.replace(".", ",");
	}

	function setRequiredTrue() {
		if ($("#OrderForm_InvoiceAddresSameAsDeliveryAdress").checked) {
			if (!$(".invoice-form").is(":visible")) {
				$(".invoice-form input").each(function () {
					$(this).rules('add', {
						required: true   // set a new rule
					});
				});
			}
		}
	}

	function setRequiredFalse() {
		if ($("#OrderForm_InvoiceAddresSameAsDeliveryAdress").checked) {
			if (!$(".invoice-form").is(":visible")) {
				$(".invoice-form input").each(function () {
					$(this).rules('add', {
						required: false   // set a new rule
					});
				});
			}
		}
	}

	$(function () {
		new initOrderCalculation();
		new validationIcons();

		$("#OrderForm_InvoiceAddresSameAsDeliveryAdress").change(function () {
			if (!this.checked) {
				$(".invoice-form").show();
				setRequiredTrue();
			} else {
				$(".invoice-form").hide();
			}
		});

		var inputFields = $("form input");
		inputFields.each(function (index, field) {
			if (typeof $(field).attr("placeholder") !== "undefined") {
				$(field).attr("placeholder", $(field).attr("placeholder").replace(/<.*?>/g, ""));
			}
		});

		$("form .button").on("click", function () {
			setRequiredFalse();
		});
	});
})(jQuery);