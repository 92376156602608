
$(document).ready(
	function () {
		var debounced = null;

		var resizeVars = {};

		function startResizing() {
			// Find all YouTube videos
			resizeVars.$allVideos = $("iframe[src^='https://www.youtube.com']"),

			// The element that is fluid width
			resizeVars.$fluidEl = $("body");

			// Figure out and save aspect ratio for each video
			resizeVars.$allVideos.each(function () {

				$(this)
					.data('aspectRatio', this.height / this.width)

					// and remove the hard coded width/height
					.removeAttr('height')
					.removeAttr('width')
					.wrap("<div class='youtube-frame'></div>");
			});

			$(window).on('resize', function () {
				if (debounced !== null) {
					clearTimeout(debounced);
					debounced = null;
				}
			
				debounced = setTimeout(onResize, 50);
			});

			onResize();
		}

		function onResize() {
			debounced = null;
			//var newWidth = resizeVars.$fluidEl.width();

			// Resize all videos according to their own aspect ratio
			resizeVars.$allVideos.each(function () {
				//height is currently not always working
				var $el = $(this);
				var newWidth = $el.parent().width();

				
				$el
					.width(newWidth)
					.height(newWidth * $el.data('aspectRatio'));

			});

			// Kick off one resize to fix all videos on page load
		}

		startResizing();

	}
		);