(function($) {
	var onSubmit = function () {
		var self = this;

		this.maxTries = 80;
		this.form = $(".order-form");
		this.buckarooForm = null;
		this.foundBuckarooForm = false;
		this.tries = 0;

		this.form.submit(function (e) {
			self.findBuckarooForm(self.tries);
		});
	};

	onSubmit.prototype.findBuckarooForm = function (tries) {
		var self = this;

		this.buckarooForm = $("#buckarooPostForm");
		if (this.buckarooForm.length > 0) {
			$(this.buckarooForm).find("#submit").click();
			return;
		} else if (tries < this.maxTries) {
			tries++;
			setTimeout(function() { self.findBuckarooForm(tries); }, 250);
		}
	};

	$(function() {
		new onSubmit();
	});
})(jQuery);